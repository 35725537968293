import React from "react";
import Layout from "../components/common/Layout";
import Section from "../components/common/Section";
import SEO from "../components/common/SEO";
import { H1, P } from "../components/common/Typography";
import imageHero from "../assets/images/royale-academy-cover.png";
import imageLogo from "../assets/images/royal-acadmey-logo.png";

const TITLE = "Royal Academy of Dance";

function RoyalAcademyPage() {
  return (
    <Layout>
      <SEO title={TITLE} />
      <img
        css={`
          width: 100%;
          vertical-align: bottom;
          max-height: 476px;
          object-fit: cover;
        `}
        src={imageHero}
        alt="Royal Academy"
      />
      <Section.Main>
        <H1>{TITLE}</H1>
        <P>
          Η σχολή μας είναι εξεταστικό κέντρο της Βασιλικής Ακαδημίας Χορού.
        </P>

        <img
          css={`
            width: 600px;
            max-width: 100%;
            object-fit: cover;
          `}
          src={imageLogo}
          alt="Royal Academy Logo"
        />
      </Section.Main>
    </Layout>
  );
}

export default RoyalAcademyPage;
